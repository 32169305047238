import React, { useState } from "react";

// SERVICES
import AuthService from "~/services/auth";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle } from "~/components/BasePage";

// STYLES
import { ButtonRelatorio } from "./styles";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from "~/components/Buttons/Small/Primary";

// COMPONENTES RELATÓRIOS
import RelatorioDeVendas from "./sub/Vendas";
import RelatorioDeTransacoesTEF from "./sub/TransacoesTEF";
import RelatorioDeTransacoesTEFErro from "./sub/TransacoesTEFErro";
// import RankingVendasPorCliente from "./RankingVendasPorCliente";
// import RelatorioTrasacoesOnline from "./RelatorioTransacoesOnline/";
// import RelatorioVendasContabilidade from "./RelatorioVendasContabilidade";
// import RelatorioEnderecosAprovados from "./RelatorioEnderecosAprovados";

export default function PageRelatorios() {
    const [RelatorioSelecionado, setRelatorioSelecionado] = useState("");

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">
                <BasePageTitleContainer>
                    <BasePageTitle>Relatórios</BasePageTitle>
                </BasePageTitleContainer>

                <BasePageBox id="box">
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>

                        <ButtonRelatorio
                            selected={RelatorioSelecionado == "VENDAS"}
                            onClick={() => setRelatorioSelecionado("VENDAS")}>
                            Vendas
                        </ButtonRelatorio>

                        <ButtonRelatorio
                            selected={RelatorioSelecionado == "TRANSACOES_TEF"}
                            onClick={() => setRelatorioSelecionado("TRANSACOES_TEF")}>
                            Transações TEF
                        </ButtonRelatorio>

                        <ButtonRelatorio
                            selected={RelatorioSelecionado == "TRANSACOES_TEF_ERRO"}
                            onClick={() => setRelatorioSelecionado("TRANSACOES_TEF_ERRO")}>
                            Transações TEF com Erro
                        </ButtonRelatorio>
                        {/* {AuthService.checkPermission("relatorios", "fechamento_de_caixa") && (
                           
                        )} */}

                        {/* {AuthService.checkPermission("relatorios", "relatorio_de_vendas") && (
                              )} */}
                        {/* <ButtonRelatorio
                            selected={RelatorioSelecionado == "RELATORIO_DE_VENDAS"}
                            onClick={() => setRelatorioSelecionado("RELATORIO_DE_VENDAS")}>
                            Relatório de vendas
                        </ButtonRelatorio> */}


                        {AuthService.checkPermission("relatorios", "relatorio_vendas_contabilidade") && (
                            <ButtonRelatorio
                                selected={RelatorioSelecionado == "RELATORIO_DE_VENDAS_CONTABILIDADE"}
                                onClick={() => setRelatorioSelecionado("RELATORIO_DE_VENDAS_CONTABILIDADE")}>
                                Relatório de vendas - contabilidade
                            </ButtonRelatorio>
                        )}

                        {AuthService.checkPermission("relatorios", "ranking_vendas_por_cliente") && (
                            <ButtonRelatorio
                                selected={RelatorioSelecionado == "RANKING_DE_VENDAS_POR_CLIENTE"}
                                onClick={() => setRelatorioSelecionado("RANKING_DE_VENDAS_POR_CLIENTE")}>
                                Ranking de vendas por cliente
                            </ButtonRelatorio>
                        )}

                        {AuthService.checkPermission("relatorios", "relatorio_transacoes_online") && (
                            <ButtonRelatorio
                                selected={RelatorioSelecionado == "RELATORIO_DE_TRANSACOES_ONLINE"}
                                onClick={() => setRelatorioSelecionado("RELATORIO_DE_TRANSACOES_ONLINE")}>
                                Relatório de transações online
                            </ButtonRelatorio>
                        )}

                        {AuthService.checkPermission("relatorios", "relatorio_enderecos_aprovados") && (
                            <ButtonRelatorio
                                selected={RelatorioSelecionado == "RELATORIO_ENDERECOS_APROVADOS"}
                                onClick={() => setRelatorioSelecionado("RELATORIO_ENDERECOS_APROVADOS")}>
                                Relatório de endereços aprovados
                            </ButtonRelatorio>
                        )}
                    </div>

                    <div style={{ marginTop: 20 }}>
                        {RelatorioSelecionado == "VENDAS" && <RelatorioDeVendas />}
                        {RelatorioSelecionado == "TRANSACOES_TEF" && <RelatorioDeTransacoesTEF />}
                        {RelatorioSelecionado == "TRANSACOES_TEF_ERRO" && <RelatorioDeTransacoesTEFErro />}

                        {/*{RelatorioSelecionado == "RELATORIO_DE_VENDAS" && <RelatorioDeVendas />}

                        {RelatorioSelecionado == "RELATORIO_DE_VENDAS_CONTABILIDADE" && <RelatorioVendasContabilidade />}

                        {RelatorioSelecionado == "RANKING_DE_VENDAS_POR_CLIENTE" && <RankingVendasPorCliente />}

                        {RelatorioSelecionado == "RELATORIO_DE_TRANSACOES_ONLINE" && <RelatorioTrasacoesOnline />}

                        {RelatorioSelecionado == "RELATORIO_ENDERECOS_APROVADOS" && <RelatorioEnderecosAprovados />} */}
                    </div>
                </BasePageBox>
            </BasePageContainer>
        </BasePageMain>
    );
}
