import React, { useRef } from "react";

import { createContext } from "use-context-selector";

import { io, Socket } from "socket.io-client";

interface IWebRTCSocketContext {
    socket: Socket | null;
}

interface IWebRTCSocketProvider {
    children: React.ReactChild;
}

export const WebRTCSocketContext = createContext<IWebRTCSocketContext>({ socket: null });

const WebRTCSocketProvider = ({ children }: IWebRTCSocketProvider) => {
    // deixar o autoconnect false para poder registrar os listeners corretamente
    // lá no hook, tem a função que conecta no servidor
    const socket = useRef<Socket>(io("https://ws-painel-linvix.linvix.com/web-rtc", { autoConnect: false }));
    // const socket = useRef<Socket>(io("http://localhost:3333/web-rtc", { autoConnect: false }));

    return <WebRTCSocketContext.Provider value={{ socket: socket.current }}>{children}</WebRTCSocketContext.Provider>;
};

export default WebRTCSocketProvider;
