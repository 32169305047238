import { useEffect, useRef } from "react";

import flatpickr from "flatpickr";
import Locale from "flatpickr/dist/l10n";

import InputNormal from "./Normal";

import moment from "~/utils/moment";

const InputNormalDateTime = ({ defaultDate = undefined, disabled = undefined, onChange = (e) => {} }) => {
    const inputRef = useRef();

    const flatpickrRef = useRef();

    const OnChange = (dates) => {
        if (onChange) {
            onChange(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
        }
    };

    useEffect(() => {
        flatpickrRef.current = flatpickr(inputRef.current, {
            //
            locale: Locale.pt,

            altInput: true,
            altFormat: "d/m/Y H:i:S",

            allowInput: true,
            time_24hr: true,

            enableTime: true,
            enableSeconds: true,

            onChange: OnChange,
        });

        return () => {
            flatpickrRef?.current?.destroy();
        };
    }, [inputRef, disabled]);

    useEffect(() => {
        if (defaultDate) {
            flatpickrRef.current?.setDate(defaultDate);
        }
    }, [defaultDate, inputRef]);

    return <InputNormal disabled={disabled} ref={inputRef} />;
};

export default InputNormalDateTime;
