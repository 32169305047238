import numeral from '~/utils/numeral';

const AjustaDecimal = (valor, casas_decimais = 2) => {
    return parseFloat(parseFloat(valor).toFixed(casas_decimais));
}

const FormataValor = (val, rs = true) => {
    const casas_decimais_valor_numeral = window?.configuracoes?.['retaguarda.geral.casas_decimais_numeral.valor'] || '00';

    return numeral(parseFloat(val)).format(`${rs ? '$ ' : ''}0,0.${casas_decimais_valor_numeral}`);
}

const FormataTotal = (val, rs = true) => {
    return numeral(parseFloat(val)).format(`${rs ? '$ ' : ''}0,0.00`);
}

const MascaraInputValor2Dig = (val) => {

    // se a quantidade existir
    if (val) {

        // subsituo o ponto por vírgula
        const qtd = (val)?.toString()?.replace(/[\.]/g, ",");

        // mascara como deveria (10 digitos ao total, 8 antes da , e 2 depois da ,)
        const newqtd = qtd.match(/[0-9]{0,8}[,]{0,1}[0-9]{0,2}/g);

        if (newqtd) {
            return newqtd[0];
        }

        return -1;
    } else {
        // se não existir o valor, tem que retornar o valor original
        return '';
    }

}

const MascaraInputDecimal2Dig = (val) => {

    // se a quantidade existir
    if (val) {

        // subsituo a vírgula por ponto
        const qtd = (val)?.toString()?.replace(/[\,]/g, ".");

        // mascara como deveria (10 digitos ao total, 8 antes do . e 2 depois do .)
        const newqtd = qtd.match(/[0-9]{0,8}[.]{0,1}[0-9]{0,2}/g);

        if (newqtd) {
            return newqtd[0];
        }

        return -1;
    } else {
        // se não existir o valor, tem que retornar o valor original
        return '';
    }

}

const MascaraInputDecimal4Dig = (val) => {

    // se a quantidade existir
    if (val) {

        // subsituo a vírgula por ponto
        const qtd = (val)?.toString()?.replace(/[\,]/g, ".");

        // mascara como deveria (12 digitos ao total, 8 antes do . e 4 depois do .)
        const newqtd = qtd.match(/[0-9]{0,8}[.]{0,1}[0-9]{0,4}/g);

        if (newqtd) {
            return newqtd[0];
        }

        return -1;
    } else {
        // se não existir o valor, tem que retornar o valor original
        return '';
    }

}

const SomenteNumeros = (val) => {
    return val?.replace(/\D/g, '');
}

// deixar assim pra poder usar intelisense
const Utils = {
    AjustaDecimal,
    FormataValor,
    FormataTotal,
    MascaraInputValor2Dig,
    MascaraInputDecimal2Dig,
    MascaraInputDecimal4Dig,
    SomenteNumeros,
}

export default Utils;