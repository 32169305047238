import { useEffect, useState, useCallback, useRef } from "react";
import { useContextSelector } from "use-context-selector";

// CONTEXTS
import { SelfCheckoutsSocketContext } from "~/contexts/selfcheckouts-socket";

/**
 * Eventos disponíveis
 */
export const WS_EVENT_JOIN = "join";
export const WS_EVENT_CONNECTED = "connected";
export const WS_EVENT_UPDATED = "updated";
export const WS_EVENT_REQUEST_ONLINE = "request-onlines";

type onWSConnectParams = {
    current: boolean;
    last: boolean;
};

type Props = {
    onWSConnect?: (state: onWSConnectParams) => void;
};

export const useSelfCheckoutsWS = ({ onWSConnect = () => {} }: Props) => {
    const socket = useContextSelector(SelfCheckoutsSocketContext, (data) => data.socket);

    const [ws_connected, setWSConnected] = useState<boolean | undefined>(false);

    const LastWSConnected = useRef<boolean>(false);

    const goSetWSConnected = useCallback(() => {
        setWSConnected(true);
    }, []);

    const goSetWSNotConnected = useCallback(() => {
        setWSConnected(false);
    }, []);

    const wsOn = useCallback(
        (ev: string, callback = () => {}) => {
            socket?.on(ev, callback);
        },
        [socket?.connected],
    );

    const wsOff = useCallback(
        (ev: string, callback = () => {}) => {
            socket?.off(ev, callback);
        },
        [socket?.connected],
    );

    const wsEmit = useCallback(
        (ev: string, data: any) => {
            socket?.emit(ev, data);
        },
        [socket?.connected],
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            // callback
            onWSConnect({
                last: LastWSConnected.current === true,
                current: ws_connected === true,
            });
            // atualiza o ultimo estado
            LastWSConnected.current = ws_connected === true ? true : false;
        }, 50);
        return () => clearTimeout(timeout);
    }, [ws_connected]);

    useEffect(() => {
        if (!socket) {
            return;
        }
        const timeout = setTimeout(async () => {
            // registra os listeners
            socket.io.on("reconnect", goSetWSConnected);
            socket.on("disconnect", goSetWSNotConnected);
            socket.on("connect", goSetWSConnected);

            // listeners
            // socket.on(EVENT_CALL, onCall);
            // socket.on(EVENT_OFFER, onOffer);
            // socket.on(EVENT_ANSWER, onAnswer);
            // socket.on(EVENT_CANDIDATE, onCandidate);

            // socket.on(EVENT_JOINED, onJoined);
            // socket.on(EVENT_LEFT, onLeft);

            // solicita a conexão
            // autoconnect desativado por padrão
            socket.connect();
        }, 10);

        return () => {
            // reseta o timeout
            clearTimeout(timeout);

            // desconecta o websocket caso ele exita
            socket?.disconnect();

            // limpa os listeners
            socket.io.off("reconnect", goSetWSConnected);
            socket.off("disconnect", goSetWSNotConnected);
            socket.off("connect", goSetWSConnected);

            // limpa os listeners
            // socket.off(EVENT_CALL, onCall);
            // socket.off(EVENT_OFFER, onOffer);
            // socket.off(EVENT_ANSWER, onAnswer);
            // socket.off(EVENT_CANDIDATE, onCandidate);

            // socket.off(EVENT_JOINED, onJoined);
            // socket.off(EVENT_LEFT, onLeft);
        };
    }, [socket]);

    return {
        connected: ws_connected,

        on: wsOn,
        off: wsOff,
        emit: wsEmit,
    };
};
