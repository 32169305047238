import React, { useRef } from "react";

import { createContext } from "use-context-selector";

import { io, Socket } from "socket.io-client";

interface ISelfCheckoutsSocketContext {
    socket: Socket | null;
}

interface ISelfCheckoutsSocketProvider {
    children: React.ReactChild;
}

export const SelfCheckoutsSocketContext = createContext<ISelfCheckoutsSocketContext>({ socket: null });

const SelfCheckoutsSocketProvider = ({ children }: ISelfCheckoutsSocketProvider) => {
    // deixar o autoconnect false para poder registrar os listeners corretamente
    // lá no hook, tem a função que conecta no servidor
    const socket = useRef<Socket>(io("https://ws-painel-linvix.linvix.com/self-checkouts", { autoConnect: false }));
    // const socket = useRef<Socket>(io("http://localhost:3333/self-checkouts", { autoConnect: false }));

    return <SelfCheckoutsSocketContext.Provider value={{ socket: socket.current }}>{children}</SelfCheckoutsSocketContext.Provider>;
};

export default SelfCheckoutsSocketProvider;
