import React, { useState, useEffect, useRef } from "react";

import Select from "react-select";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import { ColSm2, ColSm3, ColSm4, ColSm5, ColSm6, ColSm12 } from "~/components/Bootstrap/Col";

// COMPONENTES GERAIS
import Spinner from "~/components/Spinner";
import ButtonPrimary from "~/components/Buttons/Small/Primary";
import DateCalendarModalV2 from "~/components/DateCalendarModalV2";

// COMPONENTES INTERNOS
import { LabelInput, Footer, ButtonEveryPeriod } from "../styles";

// SERVICES
import APIRequests from "~/services/requests/painel";
import AuthService from "~/services/auth";

// UTILS
import Utils from "~/utils";
import moment from "~/utils/moment";
import NotificacaoUtil from "~/utils/notificacao.js";
import InputNormalDateTime from "~/components/Inputs/Normal/DateTime";

/**
 * Opções de pagamento
 */
const OptFormasPagamento = [
    { value: "CREDITO", label: "Crédito" },
    { value: "DEBITO", label: "Débito" },
    { value: "VOUCHER", label: "Voucher" },
    { value: "CARTEIRA_VIRTUAL", label: "Carteira Virtual" },
];

/**
 * Opções das transações com erro
 */
const OptTransacaoErro = [
    { value: "sucesso", label: "Somente transações com sucesso" },
    { value: "erro", label: "Somente transações com erro" },
    { value: "ambos", label: "Ambas" },
];

/**
 * Opções de status das transações
 */
const OptStatusTransacao = [
    { value: "PAGAMENTO APROVADO", label: "Pagamento Aprovado" },
    { value: "PAGAMENTO RECUSADO", label: "Pagamento Recusado" },
    { value: "PAGAMENTO ABORTADO", label: "Pagamento Abortado" },
    { value: "PAGAMENTO ESTORNADO", label: "Pagamento Estornado" },
    { value: "ERRO", label: "Pagamento com erro" },
];

/**
 * Ordenação
 */
const OptOrdenarPor = [
    { value: "valor", label: "Valor" },
    { value: "data", label: "Data da transação" },
    { value: "data_confirmacao", label: "Data da confirmação" },
    { value: "data_cancelamento", label: "Data do cancelamento" },
];
const OptOrdem = [
    { value: "DESC", label: "Decrescente" },
    { value: "ASC", label: "Crescente" },
];

export default function RelatorioDeTransacoesTEF() {
    /**
     * Referência do modal
     */
    const RefModalData = useRef(null);

    /**
     * Datas no formato americano
     */
    const StartDateUS = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
    const EndDateUS = moment().format("YYYY-MM-DD HH:mm:ss");

    /**
     * Carregando
     */
    const [Loading, setLoading] = useState(false);

    /**
     * Datas do filtro
     */
    const [DataInicio, setDataInicio] = useState(StartDateUS);
    const [DataFim, setDataFim] = useState(EndDateUS);

    const [DataDisabled, setDataDisabled] = useState(false);
    const [AllPeriodActive, setAllPeriodActive] = useState(false);

    const [FormasPagamento, setFormasPagamento] = useState([]);
    const [StatusTransacao, setStatusTransacao] = useState([]);
    const [TransacaoErro, setTransacaoErro] = useState(OptTransacaoErro[0]);

    const [OrdenarPor, setOrdenarPor] = useState(OptOrdenarPor[0]);
    const [Ordem, setOrdem] = useState(OptOrdem[0]);

    /**
     * Emitentes Disponíveis
     */
    const [EmitentesDisponiveis, setEmitentesDisponiveis] = useState([]);

    /**
     * Array de usuários localizados.
     */
    const [EmitenteSelecionado, setEmitenteSelecionado] = useState();

    // /**
    //  * Abre o modal de data.
    //  */
    // const onSelectDataInicio = async () => {
    //     const date = await RefModalData.current.RequestDate({ current_date: DataInicio });

    //     if (date.status == "ok") {
    //         const nw_date = moment(date.date).format("YYYY-MM-DD");

    //         setDataInicio(nw_date);
    //     }
    // };

    // /**
    //  * Abre o modal de data.
    //  */
    // const onSelectDataFim = async () => {
    //     const date = await RefModalData.current.RequestDate({ current_date: DataFim });

    //     if (date.status == "ok") {
    //         const nw_date = moment(date.date).format("YYYY-MM-DD");

    //         setDataFim(nw_date);
    //     }
    // };

    /**
     * Envia dados para gerar pdf
     */
    const onGeneratePDF = async () => {
        try {
            setLoading(true);

            if (!EmitenteSelecionado?.value) {
                throw new Error("Selecione um emitente para gerar o relatório");
            }

            const data = {
                emitente: Utils.SomenteNumeros(EmitenteSelecionado.value),
                transacao_erro: TransacaoErro.value,
                periodo: {
                    completo: AllPeriodActive,
                    inicio: DataInicio,
                    fim: DataFim,
                },
                ordenacao: {
                    campo: OrdenarPor.value,
                    tipo: Ordem.value,
                },
                formas_pagamento: FormasPagamento.map((forma) => forma.value),
                status: StatusTransacao.map((status) => status.value),
            };

            const response = await APIRequests.relatorios.transacoes_tef(data);

            if (response.url) {
                window.open(response.url);
            } else {
                throw new Error("Erro ao gerar o relatório");
            }
        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    /**
     * Marca todo o periodo
     */
    const onSelectEveryPeriod = () => {
        if (DataDisabled) {
            setDataDisabled(false);
            setAllPeriodActive(false);

            setDataInicio(StartDateUS);
            setDataFim(EndDateUS);
        } else {
            setDataDisabled(true);
            setAllPeriodActive(true);

            setDataInicio("");
            setDataFim("");
        }
    };

    /**
     * Carrega os emitentes
     */
    useEffect(() => {
        const user = AuthService.getUserData();

        const emitentes = user?.emitentes ?? [];

        /**
         * Gera a array com os novos dados.
         */
        const options = emitentes.map((reg) => ({
            label: `${reg.emitente_dados.nome} - ${reg.emitente_dados.cpf_cnpj}`,
            value: Utils.SomenteNumeros(reg.emitente_dados.cpf_cnpj),

            ...reg.emitente_dados,
        }));

        /**
         * Coloca na array
         */
        setEmitentesDisponiveis(options);
    }, []);

    return (
        <div id="report">
            <div id="box">
                <Row>
                    <ColSm12>
                        <LabelInput>Emitente</LabelInput>
                        <Select
                            autoFocus
                            options={EmitentesDisponiveis}
                            value={EmitenteSelecionado}
                            placeholder="Pesquise o emitente para gerar o relatório"
                            onChange={(option) => {
                                setEmitenteSelecionado(option);
                            }}
                        />
                    </ColSm12>
                </Row>

                <Row>
                    <ColSm5>
                        <LabelInput>Periodo - Início</LabelInput>
                        <InputNormalDateTime defaultDate={DataInicio} onChange={(date) => setDataInicio(date)} disabled={DataDisabled} />
                    </ColSm5>
                    <ColSm5>
                        <LabelInput>Periodo - Fim</LabelInput>
                        <InputNormalDateTime defaultDate={DataFim} onChange={(date) => setDataFim(date)} disabled={DataDisabled} />
                    </ColSm5>

                    <ColSm2>
                        <LabelInput>&nbsp;</LabelInput>
                        <ButtonEveryPeriod active={AllPeriodActive} onClick={onSelectEveryPeriod}>
                            TODO O PERÍODO
                        </ButtonEveryPeriod>
                    </ColSm2>
                </Row>
                <br />

                <Row>
                    <ColSm4>
                        <LabelInput>Transação com erro?</LabelInput>
                        <Select placeholder={null} options={OptTransacaoErro} onChange={(option) => setTransacaoErro(option)} value={TransacaoErro} />
                    </ColSm4>

                    <ColSm3>
                        <LabelInput>Forma de pagamento</LabelInput>
                        <Select isMulti={true} placeholder={null} options={OptFormasPagamento} onChange={(options) => setFormasPagamento(options)} value={FormasPagamento} />
                    </ColSm3>

                    <ColSm5>
                        <LabelInput>Status transação</LabelInput>
                        <Select isMulti={true} placeholder={null} options={OptStatusTransacao} onChange={(option) => setStatusTransacao(option)} value={StatusTransacao} />
                    </ColSm5>
                </Row>

                <Row>
                    <ColSm6>
                        <LabelInput>Ordenar por</LabelInput>
                        <Select placeholder={null} options={OptOrdenarPor} onChange={(options) => setOrdenarPor(options)} value={OrdenarPor} />
                    </ColSm6>

                    <ColSm6>
                        <LabelInput>Ordem</LabelInput>
                        <Select placeholder={null} options={OptOrdem} onChange={(opt) => setOrdem(opt)} value={Ordem} />
                    </ColSm6>
                </Row>
            </div>

            <Footer>
                <ButtonPrimary disabled={Loading} onClick={onGeneratePDF}>
                    {Loading ? <Spinner color="#fff" size={14} /> : "GERAR PDF"}
                </ButtonPrimary>
            </Footer>

            <DateCalendarModalV2 ref={RefModalData} />
        </div>
    );
}
