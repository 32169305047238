// SUB
import Modulos from './modulos';
import SelfCheckouts from './self_checkouts';
import MonitoradoresSelf from './monitoradores_self';
import Relatorios from './relatorios';

const APIRequestsPainel = {

    modulos: Modulos,

    self_checkouts: SelfCheckouts,

    monitoradores: MonitoradoresSelf,

    relatorios: Relatorios,

}

export default APIRequestsPainel;