import styled from 'styled-components';

const OnlineOffline = styled.div`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    margin-right: 5px;
    background-color: ${props => props.online ? '#2c7e2c' : '#b95656'};
`;

export default OnlineOffline;