import styled from "styled-components";

export const BaseCard = styled.div`
    display: flex;
    font-family: "Inter";

    min-width: 200px;
    min-height: 40px;

    border: 1px solid #e1e1e1;

    border-radius: 4px;

    background-color: #f8f8f8;

    padding-left: 10px;
    padding-right: 12px;

    padding-top: 4px;
    padding-bottom: 4px;

    opacity: ${props => props.online ? 1 : 0.6};

    cursor: ${props => props.online && props.webrtcOnline ? 'pointer' : 'not-allowed'};

    border-left-width: ${props => props.online ? 2 : 4}px;
    border-left-color: ${props => props.online ? '#2c7e2c' : '#b95656'};
`;

export const CardTitle = styled.span`
    display: inline-block;
    font-size: 14px;
    font-family: inherit;
    color: #444;
    font-weight: 500;
`;
