import { useEffect, useState, useCallback, useRef } from "react";
import { useContextSelector } from "use-context-selector";

// CONTEXTS
import { WebRTCSocketContext } from "~/contexts/webrtc-socket";

// SERVICES
import WebRTCService from "~/services/web-rtc";

// TYPES
import WebRTCUser from "~/types/WebRTCUser";

/**
 * Eventos disponíveis
 */
const EVENT_JOIN = "join";
const EVENT_JOINED = "joined";

const EVENT_LEAVE = "leave";
const EVENT_LEFT = "left";

const EVENT_CALL = "call";

const EVENT_OFFER = "offer";
const EVENT_ANSWER = "answer";
const EVENT_CANDIDATE = "candidate";

// const EVENT_CONNECTED = "connected";
const EVENT_DISCONNECTED = "disconnected";

type onWSConnectParams = {
    current: boolean;
    last: boolean;
};

type onRoomConnectParams = {
    current: boolean;
    last: boolean;
    room: string;
};

type Props = {
    onWSConnect?: (state: onWSConnectParams) => void;
    onRoomConnect?: (state: onRoomConnectParams) => void;
};

export const useWebRTCWS = ({ onWSConnect = () => {}, onRoomConnect = () => {} }: Props) => {
    const socket = useContextSelector(WebRTCSocketContext, (data) => data.socket);

    const [ws_connected, setWSConnected] = useState<boolean | undefined>(false);
    const [room_connected, setRoomConnected] = useState<boolean | undefined>(false);

    const HasCamera = useRef<boolean>(false);
    const HasMicrophone = useRef<boolean>(false);

    const CurrentRoomConnected = useRef<string>("");

    const LastWSConnected = useRef<boolean>(false);
    const LastRoomConnected = useRef<boolean>(false);

    const ConnectedUsers = useRef<Map<string, WebRTCUser>>(new Map());

    const LocalStream = useRef<MediaStream>();

    const goJoinRoom = useCallback(
        (serial = "") => {
            if (!socket?.connected) {
                throw new Error("Websocket is not connected");
            }
            socket?.emit(EVENT_JOIN, {
                room: serial,
            });
        },
        [socket?.connected],
    );

    const goLeaveRoom = useCallback(() => {
        if (!socket?.connected) {
            throw new Error("Websocket is not connected");
        }
        if (!CurrentRoomConnected.current) {
            throw new Error("Not connected into room");
        }
        socket?.emit(EVENT_LEAVE, {
            room: CurrentRoomConnected.current,
        });
    }, [socket?.connected, CurrentRoomConnected?.current]);

    const goMute = (mute = true) => {
        const audio_track = LocalStream?.current?.getAudioTracks()[0];
        if (audio_track) {
            audio_track.enabled = !mute;
        }
    };

    const goCheckIsMuted = () => {
        return !LocalStream?.current?.getAudioTracks()[0]?.enabled || false;
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            // callback
            onWSConnect({
                last: LastWSConnected.current === true,
                current: ws_connected === true,
            });
            // atualiza o ultimo estado
            LastWSConnected.current = ws_connected === true ? true : false;
        }, 50);
        return () => clearTimeout(timeout);
    }, [ws_connected]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            // callback
            onRoomConnect({
                last: LastRoomConnected.current === true,
                current: room_connected === true,
                room: CurrentRoomConnected.current,
            });
            // atualiza o ultimo estado
            LastRoomConnected.current = room_connected === true ? true : false;
        }, 50);
        return () => clearTimeout(timeout);
    }, [room_connected]);

    const goSetWSConnected = useCallback(() => {
        setWSConnected(true);
    }, []);

    const goSetWSNotConnected = useCallback(() => {
        setWSConnected(false);
    }, []);

    const goCreatePeer = async (user: WebRTCUser) => {
        const peer = WebRTCService.CreatePeer();
        // quando recebe o candidate, envia para o websocket
        peer.onicecandidate = (ev) => {
            if (!ev.candidate) return;
            // emite a offer
            socket?.emit(EVENT_CANDIDATE, { socket_id: user.id, candidate: ev.candidate });
        };
        // adiciona no browser para ver/ouvir
        peer.ontrack = (ev) => {
            if (!user.player) {
                user.setPlayer(ev.streams[0]);
            }
        };
        // peer.onconnectionstatechange = (ev) => {
        //     console.log(ev);
        // };
        // adiciona as tracks do stream local na peer
        if (LocalStream.current) {
            for (const track of LocalStream.current?.getTracks()) {
                peer.addTrack(track, LocalStream.current);
            }
        }
        // retorna a peer
        return peer;
    };

    const goCreateOffer = async (pc: RTCPeerConnection) => {
        return await WebRTCService.CreateOffer(pc);
    };

    const goCreateAnswer = async (pc: RTCPeerConnection, offer: RTCSessionDescriptionInit) => {
        return await WebRTCService.CreateAnswer(pc, offer);
    };

    const onCall = useCallback(async (ev: any) => {
        // cria o usuário
        const user = new WebRTCUser(ev.socket_id);
        // cria a peer
        user.pc = await goCreatePeer(user);
        // cria a offer
        const offer = await goCreateOffer(user.pc);
        // adiciona na array de usuários
        ConnectedUsers.current.set(ev.socket_id, user);
        // emite a offer
        socket?.emit(EVENT_OFFER, { socket_id: ev.socket_id, offer });
    }, []);

    const onOffer = useCallback(async (ev: any) => {
        let user = ConnectedUsers.current.get(ev.socket_id);

        if (!user) {
            // cria o usuário
            user = new WebRTCUser(ev.socket_id);
            // cria a peer
            user.pc = await goCreatePeer(user);
            // adiciona na array de usuários
            ConnectedUsers.current.set(ev.socket_id, user);
        }

        if (user.pc) {
            // cria a resposta
            const answer = await goCreateAnswer(user.pc, ev.offer);
            // emite a answer
            socket?.emit(EVENT_ANSWER, { socket_id: ev.socket_id, answer });
        }
    }, []);

    const onAnswer = useCallback((ev: any) => {
        const user = ConnectedUsers.current.get(ev.socket_id);
        if (user?.pc) {
            user.pc.setRemoteDescription(ev.answer);
        }
    }, []);

    const onCandidate = useCallback(async (ev: any) => {
        let user = ConnectedUsers.current.get(ev.socket_id);

        if (!user) {
            // cria o usuário
            user = new WebRTCUser(ev.socket_id);
            // cria a peer
            user.pc = await goCreatePeer(user);
            // adiciona na array de usuários
            ConnectedUsers.current.set(ev.socket_id, user);
        }

        if (user.pc) {
            user.pc.addIceCandidate(ev.candidate);
        }
    }, []);

    const onJoined = (ev: any) => {
        // coloca a sala conecta
        CurrentRoomConnected.current = ev?.room || "";
        // informa que conectou
        setRoomConnected(true);
    };

    const onLeft = () => {
        // remove a sala conectada
        CurrentRoomConnected.current = "";
        // informa que desconectou
        setRoomConnected(false);
        // remove todos os players
        for (const user of ConnectedUsers.current.values()) {
            user.destroy();
        }
        // limpa a lista de usuários
        ConnectedUsers.current.clear();
    };

    const onDisconnected = (ev: any) => {
        const user = ConnectedUsers.current.get(ev.socket_id);
        // remove o player do usuário
        if (user?.pc) user.destroy();
        // remove da conexão dos usuários
        ConnectedUsers.current.delete(ev.socket_id);
    };

    useEffect(() => {
        const timeout = setTimeout(async () => {
            // pega os dispositivos
            const devices = await navigator.mediaDevices.enumerateDevices();

            // verifica se tem dispositivo de câmera ou video
            HasCamera.current = devices.filter((d) => d.kind === "videoinput").length > 0;
            HasMicrophone.current = devices.filter((d) => d.kind === "audioinput").length > 0;

            // pega o microfone/camera
            LocalStream.current = await navigator.mediaDevices.getUserMedia({
                // video: has_video.length > 0 ? true : false,
                video: false,
                audio: {
                    echoCancellation: true,
                    autoGainControl: true,
                    noiseSuppression: true,
                },
            });
        }, 50);
        return () => {
            // reseta o timeout
            clearTimeout(timeout);

            // para o uso do microfone/webcam
            LocalStream.current?.getTracks()?.forEach(function (track) {
                track.stop();
            });
        };
    }, []);

    useEffect(() => {
        if (!socket) {
            return;
        }
        const timeout = setTimeout(async () => {
            // registra os listeners
            socket.io.on("reconnect", goSetWSConnected);
            socket.on("disconnect", goSetWSNotConnected);
            socket.on("connect", goSetWSConnected);

            // listeners
            socket.on(EVENT_CALL, onCall);
            socket.on(EVENT_OFFER, onOffer);
            socket.on(EVENT_ANSWER, onAnswer);
            socket.on(EVENT_CANDIDATE, onCandidate);

            socket.on(EVENT_JOINED, onJoined);
            socket.on(EVENT_LEFT, onLeft);

            socket.on(EVENT_DISCONNECTED, onDisconnected);

            // solicita a conexão
            // autoconnect desativado por padrão
            socket.connect();
        }, 10);

        return () => {
            // reseta o timeout
            clearTimeout(timeout);

            // desconecta o websocket caso ele exita
            socket?.disconnect();

            // limpa os listeners
            socket.io.off("reconnect", goSetWSConnected);
            socket.off("disconnect", goSetWSNotConnected);
            socket.off("connect", goSetWSConnected);

            // limpa os listeners
            socket.off(EVENT_CALL, onCall);
            socket.off(EVENT_OFFER, onOffer);
            socket.off(EVENT_ANSWER, onAnswer);
            socket.off(EVENT_CANDIDATE, onCandidate);

            socket.off(EVENT_JOINED, onJoined);
            socket.off(EVENT_LEFT, onLeft);

            socket.off(EVENT_DISCONNECTED, onDisconnected);
        };
    }, [socket]);

    return {
        ws_connected,
        room_connected,

        join: goJoinRoom,
        leave: goLeaveRoom,
        mute: goMute,

        is_muted: goCheckIsMuted,
    };
};
