import React, { useState, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12 } from '~/components/Bootstrap/Col';

// COMPONENTES GERAIS
import Spinner from '~/components/Spinner';
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonDanger from '~/components/Buttons/Normal/Danger';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

const ModalCall = forwardRef(
    ({

    }, ref) => {

        const [isVisible, setVisible] = useState(false);

        const [Name, setName] = useState('');

        const [Muted, setMuted] = useState(false);

        const onRequestOpen = async (data = {}) => {
            // titulo do modal
            setName(`${data.serial} | ${data.operador_logado}`)

            // abre o modal
            setVisible(true);

            // conecta no ws
            window.useWebRTCWS.join(data.serial);
        }

        const onRequestClose = () => {
            try {
                window.useWebRTCWS.leave();
            } catch (error) {
                console.log('ERRO AO FECHAR A CHAMADA', error);
            }

            // fecha o modal
            setVisible(false);

            // reseta os estados
            setName('');
            setMuted(false);
        }

        const onRequestMute = () => {
            window.useWebRTCWS.mute(true);
            const muted = window.useWebRTCWS.is_muted();
            setMuted(muted);
        }

        const onRequestUnMute = () => {
            window.useWebRTCWS.mute(false);
            const muted = window.useWebRTCWS.is_muted();
            setMuted(muted);
        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={680}
                height={650}
            >
                <Main>
                    <ModalHeader>
                        {Name}
                    </ModalHeader>

                    <Content>

                        <div id="media-root" style={{ display: 'flex', flex: 1, width: 640, height: 480, justifyContent: 'center', alignItems: 'center', }}>
                            <Spinner size={50} />
                        </div>

                        <Footer>

                            <ButtonSecondary onClick={onRequestClose}>
                                Encerrar ligação
                            </ButtonSecondary>

                            {Muted ? (
                                <ButtonPrimary onClick={onRequestUnMute}>
                                    Ativar microfone
                                </ButtonPrimary>
                            ) : (
                                <ButtonDanger onClick={onRequestMute}>
                                    Desativar microfone
                                </ButtonDanger>
                            )}

                        </Footer>

                    </Content>
                </Main>
            </Modal >
        )

    }
);

export default ModalCall;