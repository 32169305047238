// API
import api from '~/services/api';

const Vendas = async (data) => {

    try {

        const { data: response_data } = await api.post(`/private/relatorios/vendas`, data);

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const TransacoesTEF = async (data) => {

    try {

        const { data: response_data } = await api.post(`/private/relatorios/transacoes_tef`, data);

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const TransacoesTEFErro = async (data) => {

    try {

        const { data: response_data } = await api.post(`/private/relatorios/transacoes_tef_erro`, data);

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const RelatoriosRequests = {
    vendas: Vendas,
    transacoes_tef: TransacoesTEF,
    transacoes_tef_erro: TransacoesTEFErro,
};

export default RelatoriosRequests;