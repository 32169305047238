import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

// HOOKS
import { useWebRTCWS } from "~/hooks/webrtc-ws";
import { useSelfCheckoutsWS, WS_EVENT_CONNECTED } from "~/hooks/selfcheckouts-ws";

// SERVICES
import AuthService from "~/services/auth";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageTitle, BasePageButtonsContainer } from "~/components/BasePage";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
// import ButtonPrimary from "~/components/Buttons/Small/Primary";
import SelectSmall from "~/components/Selects/Normal/Small";
import SearchInput from "~/components/SearchInput";

// COMPONENTES INTERNOS
import CardList, { CardListForwardRef } from "./components/card-list";
import ModalCall from "./components/modals/call";

export default function PageDashboard() {
    /**
     * Websocket que gerencia as conexões dos selfs.
     */
    const SelfCheckoutsWS = useSelfCheckoutsWS({
        onWSConnect(state) {
            console.log(`SELF CHECKOUTS WS - CURRENT ${state.current} | LAST ${state.last}`);
        },
    });

    /**
     * Websocket que gerencia a conexão com o WebRTC
     */
    window.useWebRTCWS = useWebRTCWS({
        onWSConnect(state) {
            console.log(`CALL MANAGER WS - CURRENT ${state.current} | LAST ${state.last}`);
        },

        onRoomConnect(state) {
            console.log(`CALL MANAGER ROOM - CURRENT ${state.current} | LAST ${state.last} | ${state.room}`);
        },
    });

    /**
     * Referências
     */
    const CardListREF = useRef<CardListForwardRef>(null);
    const ModalCallREF = useRef<any>();

    /**
     * Estados
     */
    const [LoadData, setLoadData] = useState<null | Date>();

    const [Emitentes, setEmitentes] = useState<any>([]);
    const [EmitenteSelecionado, setEmitenteSelecionado] = useState("");

    const [Query, setQuery] = useState("");

    const goLoadEmitentes = () => {
        const user_data = AuthService.getUserData();

        const emitentes = user_data?.emitentes || [];

        const emitentes_carregados: any[] = [];

        for (const emitente of emitentes) {
            const dados = emitente?.emitente_dados || false;

            if (dados) {
                emitentes_carregados.push(dados);
            }
        }

        setEmitentes(emitentes_carregados);

        // se tiver registros ele marca o primeiro como selecionado
        if (emitentes_carregados?.[0]?.cpf_cnpj) {
            setEmitenteSelecionado(emitentes_carregados[0].cpf_cnpj);
        }
    };

    const goLoadSelfs = async () => {
        CardListREF?.current?.load(Query, EmitenteSelecionado);
    };

    const onCardClick = useCallback((data) => {
        ModalCallREF?.current?.open(data);
    }, []);

    /**
     * Eventos do websocket
     */
    useEffect(() => {
        // registra listener de conexão do self
        SelfCheckoutsWS.on(WS_EVENT_CONNECTED, goLoadSelfs);
        return () => {
            // remove o listener
            SelfCheckoutsWS.off(WS_EVENT_CONNECTED, goLoadSelfs);
        };
    }, [SelfCheckoutsWS.connected, LoadData]);

    /**
     * Carrega os selfs quando o parâmetro de data for alterado
     */
    useEffect(() => {
        const timeout = setTimeout(() => {
            goLoadSelfs();
        }, 50);
        return () => clearTimeout(timeout);
    }, [LoadData]);

    /**
     * Quando alterar o emitente, informa que é pra carregar os selfs
     */
    useEffect(() => {
        setLoadData(new Date());
    }, [EmitenteSelecionado]);

    /**
     * Carrega os emitentes.
     */
    useEffect(() => {
        goLoadEmitentes();
    }, []);

    return useMemo(() => {
        console.log("render");

        return (
            <BasePageMain style={{ overflow: "hidden" }}>
                <NavigationBar />

                <BasePageContainer style={{ overflowY: "scroll" }}>
                    <BasePageButtonsContainer style={{ justifyContent: "space-between" }}>
                        <BasePageTitle>Dashboard</BasePageTitle>

                        <div style={{ display: "flex" }}>
                            <SearchInput
                                autoFocus
                                inputType="small"
                                onSearch={async (text) => {
                                    setQuery(text);
                                    setLoadData(new Date());
                                }}
                            />

                            <SelectSmall style={{ width: 250, marginLeft: 8 }} value={EmitenteSelecionado} onChange={(e) => setEmitenteSelecionado(e.target.value)}>
                                {Emitentes.map((emit) => {
                                    return (
                                        <option key={emit.uuid} value={emit.cpf_cnpj}>
                                            {emit.nome}
                                        </option>
                                    );
                                })}
                            </SelectSmall>
                        </div>
                    </BasePageButtonsContainer>

                    <CardList ref={CardListREF} onCardClick={onCardClick} />
                </BasePageContainer>

                <ModalCall ref={ModalCallREF} />
            </BasePageMain>
        );
    }, [Emitentes, EmitenteSelecionado, Query]);
}
