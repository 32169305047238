import styled from 'styled-components';

export const BoxLogin = styled.div`
    font-family: 'Inter';

    background: #fafafa;
    top: 15%;
    left: calc(50% - 225px); // 225px é a metade do tamanho total 450
    width: 450px;
    position: absolute;
    border-radius: 5px;
`;

export const ImageLogo = styled.img`
    margin-top: 50px;
    margin-bottom: 10px;
    width: 60%;
`;

export const Section = styled.section`
    font-family: inherit;

    width: 90%;
    margin: 0 auto;
    padding: 35px 0 0;
    text-align: center;
`;

export const Copy = styled.span`
    font-family: inherit;

    color: #333;
    display: block;
    /* text-shadow: 0 1px 0 #fff; */       
    margin-bottom: 25px;
`;
