class WebRTCUser {
    id: string;
    pc?: RTCPeerConnection;
    player?: HTMLVideoElement;

    constructor(id: string) {
        this.id = id;
    }

    setPlayer = (stream: MediaStream) => {
        // verifica se tem vídeo
        const has_video = stream.getVideoTracks().length > 0;

        // se tiver video, cria o vídeo, se não cria o áudio
        const type = has_video ? `video` : `audio`;

        // cria o template do html
        const template = new DOMParser().parseFromString(`<${type} id="video_stream_${this.id}" autoplay playsinline />`, "text/html");

        // coloca o stream na memória
        template.getElementsByTagName(type)[0].srcObject = stream;

        // gera o elemento
        const element = template.body.childNodes[0] as HTMLVideoElement;

        // remove o spinner
        document?.getElementById("spinner")?.remove();

        // adiciona no html
        document?.getElementById("media-root")?.appendChild(element);

        // se for só audio, cria o elemento do microfone
        if (has_video == false) {
            const temp_mic = new DOMParser().parseFromString(`<i class="fad fa-microphone" style="font-size: 144px; font-color: #666" />`, "text/html");
            document?.getElementById("media-root")?.appendChild(temp_mic.body.childNodes[0]);
        }

        // seta no player
        this.player = element;
    };

    destroy() {
        if (this.player) {
            this.player.remove();
        }

        if (this.pc) {
            this.pc.close();
            this.pc.onicecandidate = null;
            this.pc.ontrack = null;

            this.pc = undefined;
        }
    }
}
export default WebRTCUser;
