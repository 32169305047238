const DOMAIN = "coturn-server.linvixhosting.com.br";
const USERNAME = "painel-monitoramento";
const PASSWORD = "Binho1379";

const ICE_SERVERS_LINVIX = [
    // {
    //     urls: "stun:stun.l.google.com:19302",
    // },
    {
        urls: [`stun:${DOMAIN}:80`], // porta alternativa coturn
    },

    {
        urls: [
            // !SEM TLS
            `turn:${DOMAIN}:80?transport=tcp`,
            `turn:${DOMAIN}:80?transport=udp`,

            // !COM TLS
            `turn:${DOMAIN}:443?transport=tcp`,
            `turn:${DOMAIN}:443?transport=udp`,
        ],
        username: USERNAME,
        credential: PASSWORD,
    },
];

// const ICE_SERVERS_METERED = [
//     {
//         urls: "stun:a.relay.metered.ca:80",
//     },
//     {
//         urls: "turn:a.relay.metered.ca:80",
//         username: "ea6c664b43fe9cde3b8954d7",
//         credential: "g1SLqc7ASKS9enS1",
//     },
//     {
//         urls: "turn:a.relay.metered.ca:80?transport=tcp",
//         username: "ea6c664b43fe9cde3b8954d7",
//         credential: "g1SLqc7ASKS9enS1",
//     },
//     {
//         urls: "turn:a.relay.metered.ca:443",
//         username: "ea6c664b43fe9cde3b8954d7",
//         credential: "g1SLqc7ASKS9enS1",
//     },
//     {
//         urls: "turn:a.relay.metered.ca:443?transport=tcp",
//         username: "ea6c664b43fe9cde3b8954d7",
//         credential: "g1SLqc7ASKS9enS1",
//     },
// ];

// const ICE_SERVERS_TWILIO = [
//     {
//         // url: "stun:br1.stun.twilio.com:3478",
//         urls: "stun:br1.stun.twilio.com:3478",
//     },
//     {
//         // url: "turn:br1.turn.twilio.com:3478?transport=udp",
//         urls: "turn:br1.turn.twilio.com:3478?transport=udp",
//         username: "886be8c242ba4a4bdd342f6494b01cd64427bc51daab8098856187fa0a614599",
//         credential: "irAzMnclc59HQ6C0Bbm647A8x7x1+AfdQkDg5zVvRAw=",
//     },
//     {
//         // url: "turn:br1.turn.twilio.com:3478?transport=tcp",
//         urls: "turn:br1.turn.twilio.com:3478?transport=tcp",
//         username: "886be8c242ba4a4bdd342f6494b01cd64427bc51daab8098856187fa0a614599",
//         credential: "irAzMnclc59HQ6C0Bbm647A8x7x1+AfdQkDg5zVvRAw=",
//     },
//     {
//         // url: "turn:br1.turn.twilio.com:443?transport=tcp",
//         urls: "turn:br1.turn.twilio.com:443?transport=tcp",
//         username: "886be8c242ba4a4bdd342f6494b01cd64427bc51daab8098856187fa0a614599",
//         credential: "irAzMnclc59HQ6C0Bbm647A8x7x1+AfdQkDg5zVvRAw=",
//     },
// ];

// const ICE_SERVERS_JITSI = [
//     {
//         urls: "stun:meet-jit-si-turnrelay.jitsi.net:443",
//     },
//     {
//         urls: "turns:meet-jit-si-turnrelay.jitsi.net:443?transport=tcp",
//         username: "1685042430",
//         credential: "7hlokrh5YurIufbyTOq6fywEdOk=",
//     },
// ];

const CreatePeer = () => {
    const pc = new RTCPeerConnection({
        iceServers: ICE_SERVERS_LINVIX,
    });
    return pc;
};

const CreateOffer = async (pc: RTCPeerConnection) => {
    const offer_description = await pc.createOffer();
    await pc.setLocalDescription(offer_description);
    return offer_description;
};

const CreateAnswer = async (pc: RTCPeerConnection, offer: RTCSessionDescriptionInit) => {
    await pc.setRemoteDescription(offer);
    const answer_description = await pc.createAnswer();
    await pc.setLocalDescription(answer_description);
    return answer_description;
};

const WebRTCService = {
    CreatePeer,
    CreateOffer,
    CreateAnswer,
};

export default WebRTCService;
