import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";

// SERVICES
import APIRequestsPainel from "~/services/requests/painel";
import { BaseCardContainer } from "./styles";

// COMPONENTES INTERNOS
import CardSelf from "../card-self";

export interface CardListForwardRef {
    load: (query: string, emitente: string) => any;
}

interface Props {
    onCardClick?: (item: any) => any;
}

const CardList = forwardRef<CardListForwardRef, Props>(({ onCardClick = () => {} }, ref) => {
    const [Selfs, setSelfs] = useState<any>([]);

    const goLoadSelfs = async (query: string, emitente: string) => {
        const self_checkouts = await APIRequestsPainel.self_checkouts.listar({
            query: query,

            sort: {
                online: "asc",
                webrtc_online: "desc",
                operador_logado: "asc",
            },

            emitente: emitente,
        });

        if (self_checkouts.results) {
            setSelfs(self_checkouts.results);
        }
    };

    /**
     * Passa as funções para fora
     */
    useImperativeHandle(ref, () => ({
        load: goLoadSelfs,
    }));

    return useMemo(() => {
        return (
            <BaseCardContainer>
                {Selfs.map((self) => {
                    return <CardSelf key={self.uuid} data={self} onClick={onCardClick} />;
                })}
            </BaseCardContainer>
        );
    }, [Selfs, onCardClick]);
});

export default CardList;
