import styled from 'styled-components'

import SelectBase from '~/components/SelectBase';

const SelectBaseSmall = styled(SelectBase)`
    width: calc(100%); // padding * 2 = 12px    2px = 1px border * 2
    height: 32px;
    padding: 6px 12px;
    font-size: 15px;
`;

export default SelectBaseSmall;