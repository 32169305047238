import styled from "styled-components";

export const Main = styled.div`
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    border-top: ${(props) => props.size * 0.1}px solid rgba(0, 0, 0, 0.1);
    border-right: ${(props) => props.size * 0.1}px solid rgba(0, 0, 0, 0.1);
    border-bottom: ${(props) => props.size * 0.1}px solid rgba(0, 0, 0, 0.1);
    border-left: ${(props) => props.size * 0.1}px solid ${(props) => props.color};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 ${(props) => props.speed}s infinite linear;
    animation: load8 ${(props) => props.speed}s infinite linear;
    border-radius: 50%;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    &:after {
        /* border-radius: 50%; */
        /* width: 10em; */
        /* height: 10em; */
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`;