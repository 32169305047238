import React from "react";
import { BrowserRouter as Router, HashRouter, MemoryRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import history from "./hooks/history";

// SERVICES
import AuthService from "~/services/auth";

// PAGES - PUBLIC
import PageLogin from "./pages/Public/Login";

// PAGES - PRIVATE
import PageHome from "./pages/Private/Home";
import PageDashboard from "./pages/Private/Dashboard";
import PageRelatorios from "./pages/Private/Relatorios";

/**
 * Proteje as rotas privadas do sistema
 */
const PrivateRoutes = () => {

    const token = AuthService.getToken();

    if (token !== false) {
        return (
            <Routes>

                <Route path="/" element={<PageHome />} />

                <Route path="/dashboard" element={<PageDashboard />} />
                <Route path="/relatorios" element={<PageRelatorios />} />

            </Routes>
        )
    } else {
        // retorna para o login
        return <Navigate to="/" />;
    }

};

/**
 * Rotas publicas do sistema
 */
const PublicRoutes = () => {
    // coloca o usenavigate no root
    window.useNavigate = useNavigate();

    // localização atual
    const location = useLocation();

    // verifica se já está logado
    const token = AuthService.getToken();

    // redireciona pra seção logada
    if (token !== false && location.pathname === '/') {
        // retorna para o login
        return <Navigate to="/app" />;
    }

    return (
        <Routes>
            <Route path="/" element={<PageLogin />} />
            <Route path="/app/*" element={<PrivateRoutes />} />
        </Routes>
    );
};

/**
 * Exportação padrão
 */
export default () => {
    return (
        <HashRouter history={history}>
            <PublicRoutes />
        </HashRouter>
    );
};
