import React, { useMemo, useCallback } from "react";

import { BaseCard, CardTitle } from "./styles";

import OnlineOffline from "./components/online-offline";
import PhoneOnline from "./components/phone-online";

export default function CardSelf({ data, onClick }) {
    const onCardClick = useCallback(() => {
        // só chama se tiver online
        if (data.online && data.webrtc_online) {
            onClick(data);
        }
    }, [onClick, data.online, data.webrtc_online]);

    return useMemo(() => {
        return (
            <BaseCard online={data.online} webrtcOnline={data.webrtc_online} onClick={onCardClick}>

                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CardTitle>
                        {data.operador_logado}
                    </CardTitle>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'space-between' }}>
                        {/* <OnlineOffline online={data.online} /> */}

                        {data.webrtc_online && (
                            <PhoneOnline online={data.webrtc_online} />
                        )}
                    </div>
                </div>

            </BaseCard>
        );
    }, [
        data?.uuid,
        data?.online,
        data?.webrtc_online,
        data?.operador_logado,
        onCardClick
    ]);
}
