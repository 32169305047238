// API
import api from '~/services/api';

import { Buffer } from 'buffer';

const Listar = async ({
    query = '',
    limit = 100,
    page = 1,

    sort,

    emitente = ''
}) => {

    try {

        const query_params_arr = [];

        query_params_arr.push(`query=${query}`);
        query_params_arr.push(`limit=${limit}`);
        query_params_arr.push(`page=${page}`);

        query_params_arr.push(`emitente=${emitente}`);

        if (sort) {
            const sorted_b64 = Buffer.from(JSON.stringify(sort)).toString('base64');
            query_params_arr.push(`sort=${sorted_b64}`);
        }

        const query_params = query_params_arr.length > 0 ? `?${query_params_arr.join('&')}` : '';

        const { data: response_data } = await api.get(`/private/self-checkouts${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}


const SelfCheckoutsRequests = {
    listar: Listar,
};

export default SelfCheckoutsRequests;