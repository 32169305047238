import React, { useState, useEffect, useRef } from "react";

import Select from "react-select";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import { ColSm2, ColSm3, ColSm4, ColSm5, ColSm6, ColSm12 } from "~/components/Bootstrap/Col";

// COMPONENTES GERAIS
import Spinner from "~/components/Spinner";
import InputNormalDateTime from "~/components/Inputs/Normal/DateTime";
import ButtonPrimary from "~/components/Buttons/Small/Primary";
import DateCalendarModalV2 from "~/components/DateCalendarModalV2";

// COMPONENTES INTERNOS
import { LabelInput, Footer, ButtonEveryPeriod } from "../styles";

// SERVICES
import APIRequests from "~/services/requests/painel";
import AuthService from "~/services/auth";

// UTILS
import Utils from "~/utils";
import moment from "~/utils/moment";
import NotificacaoUtil from "~/utils/notificacao.js";

/**
 * Opções de pagamento
 */
const OptFormaPagamento = [
    { value: "CREDITO", label: "Crédito" },
    { value: "DEBITO", label: "Débito" },
    { value: "VOUCHER", label: "Voucher" },
    { value: "CARTEIRA_VIRTUAL", label: "Carteira Virtual" },
];

/**
 * Opções das transações com erro
 */
const OptVendaFinalizada = [
    { value: "sucesso", label: "Somente vendas finalizadas" },
    { value: "erro", label: "Somente vendas não finalizadas" },
    { value: "ambos", label: "Ambas" },
];

/**
 * Opções das transações com erro
 */
const OptVendaErroSincro = [
    { value: "sucesso", label: "Somente vendas sem erro de sincronismo" },
    { value: "erro", label: "Somente vendas com erro de sincronismo" },
    { value: "ambos", label: "Ambas" },
];

/**
 * Opções de status das transações
 */
const OptVendaSituacao = [
    { value: "SALVA", label: "Venda salva" },
    { value: "ERRO PINPAD", label: "Venda com erro de pinpad" },
    { value: "FINALIZADA", label: "Venda finalizada" },
    { value: "ABANDONADA", label: "Venda abandonada" },
];

/**
 * Ordenação
 */
const OptOrdenarPor = [
    { value: "operador_nome", label: "Nome operador" },
    { value: "valor_total_pedido", label: "Valor total pedido" },
    { value: "data_hora", label: "Data da venda" },
    { value: "situacao", label: "Situação" },
    { value: "finalizado", label: "Pedido finalizado" },
    { value: "erro_sincro", label: "Erro no sincronismo" },
    { value: "pedido_id", label: "N° Pedido Retaguarda" },
    { value: "faturamento_id", label: "N° Faturamento Retaguarda" },
    { value: "nfce_numero", label: "N° NFCe Retaguarda" },
];
const OptOrdem = [
    { value: "DESC", label: "Decrescente" },
    { value: "ASC", label: "Crescente" },
];

export default function RelatorioDeVendas() {
    /**
     * Referência do modal
     */
    const RefModalData = useRef(null);

    /**
     * Datas no formato americano
     */
    const StartDateUS = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
    const EndDateUS = moment().format("YYYY-MM-DD HH:mm:ss");

    /**
     * Carregando
     */
    const [Loading, setLoading] = useState(false);

    /**
     * Datas do filtro
     */
    const [DataInicio, setDataInicio] = useState(StartDateUS);
    const [DataFim, setDataFim] = useState(EndDateUS);

    const [DataDisabled, setDataDisabled] = useState(false);
    const [AllPeriodActive, setAllPeriodActive] = useState(false);

    const [VendaSituacao, setVendaSituacao] = useState([]);
    const [VendaErroSincro, setVendaErroSincro] = useState(OptVendaErroSincro[0]);
    const [VendaFinalizada, setVendaFinalizada] = useState(OptVendaFinalizada[0]);

    const [OrdenarPor, setOrdenarPor] = useState(OptOrdenarPor[0]);
    const [Ordem, setOrdem] = useState(OptOrdem[0]);

    /**
     * Emitentes Disponíveis
     */
    const [EmitentesDisponiveis, setEmitentesDisponiveis] = useState([]);

    /**
     * Array de usuários localizados.
     */
    const [EmitenteSelecionado, setEmitenteSelecionado] = useState();

    // /**
    //  * Abre o modal de data.
    //  */
    // const onSelectDataInicio = async () => {
    //     const date = await RefModalData.current.RequestDate({ current_date: DataInicio });

    //     if (date.status == "ok") {
    //         const nw_date = moment(date.date).format("YYYY-MM-DD");

    //         setDataInicio(nw_date);
    //     }
    // };

    // /**
    //  * Abre o modal de data.
    //  */
    // const onSelectDataFim = async () => {
    //     const date = await RefModalData.current.RequestDate({ current_date: DataFim });

    //     if (date.status == "ok") {
    //         const nw_date = moment(date.date).format("YYYY-MM-DD");

    //         setDataFim(nw_date);
    //     }
    // };

    /**
     * Envia dados para gerar pdf
     */
    const onGeneratePDF = async () => {
        try {
            setLoading(true);

            if (!EmitenteSelecionado?.value) {
                throw new Error("Selecione um emitente para gerar o relatório");
            }

            const data = {
                emitente: Utils.SomenteNumeros(EmitenteSelecionado.value),
                finalizadas: VendaFinalizada.value,
                erro_sincro: VendaErroSincro.value,
                situacao: VendaSituacao.map((situacao) => situacao.value),
                periodo: {
                    completo: AllPeriodActive,
                    inicio: DataInicio,
                    fim: DataFim,
                },
                ordenacao: {
                    campo: OrdenarPor.value,
                    tipo: Ordem.value,
                },
            };

            const response = await APIRequests.relatorios.vendas(data);

            if (response.url) {
                window.open(response.url);
            } else {
                throw new Error("Erro ao gerar o relatório");
            }
        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    /**
     * Marca todo o periodo
     */
    const onSelectEveryPeriod = () => {
        if (DataDisabled) {
            setDataDisabled(false);
            setAllPeriodActive(false);

            setDataInicio(StartDateUS);
            setDataFim(EndDateUS);
        } else {
            setDataDisabled(true);
            setAllPeriodActive(true);

            setDataInicio("");
            setDataFim("");
        }
    };

    useEffect(() => {}, []);

    /**
     * Carrega os emitentes
     */
    useEffect(() => {
        const user = AuthService.getUserData();

        const emitentes = user?.emitentes ?? [];

        /**
         * Gera a array com os novos dados.
         */
        const options = emitentes.map((reg) => ({
            label: `${reg.emitente_dados.nome} - ${reg.emitente_dados.cpf_cnpj}`,
            value: Utils.SomenteNumeros(reg.emitente_dados.cpf_cnpj),

            ...reg.emitente_dados,
        }));

        /**
         * Coloca na array
         */
        setEmitentesDisponiveis(options);
    }, []);

    return (
        <div id="report">
            <div id="box">
                <Row>
                    <ColSm12>
                        <LabelInput>Emitente</LabelInput>
                        <Select
                            autoFocus
                            options={EmitentesDisponiveis}
                            value={EmitenteSelecionado}
                            placeholder="Pesquise o emitente para gerar o relatório"
                            onChange={(option) => {
                                setEmitenteSelecionado(option);
                            }}
                        />
                    </ColSm12>
                </Row>

                <Row>
                    <ColSm5>
                        <LabelInput>Periodo - Início</LabelInput>
                        <InputNormalDateTime defaultDate={DataInicio} onChange={(date) => setDataInicio(date)} disabled={DataDisabled} />
                    </ColSm5>
                    <ColSm5>
                        <LabelInput>Periodo - Fim</LabelInput>
                        <InputNormalDateTime defaultDate={DataFim} onChange={(date) => setDataFim(date)} disabled={DataDisabled} />
                    </ColSm5>

                    <ColSm2>
                        <LabelInput>&nbsp;</LabelInput>
                        <ButtonEveryPeriod active={AllPeriodActive} onClick={onSelectEveryPeriod}>
                            TODO O PERÍODO
                        </ButtonEveryPeriod>
                    </ColSm2>
                </Row>
                <br />

                <Row>
                    <ColSm3>
                        <LabelInput>Vendas finalizadas?</LabelInput>
                        <Select placeholder={null} options={OptVendaFinalizada} onChange={(option) => setVendaFinalizada(option)} value={VendaFinalizada} />
                    </ColSm3>

                    <ColSm4>
                        <LabelInput>Vendas com erro de sincronismo?</LabelInput>
                        <Select placeholder={null} options={OptVendaErroSincro} onChange={(options) => setVendaErroSincro(options)} value={VendaErroSincro} />
                    </ColSm4>

                    <ColSm5>
                        <LabelInput>Situação venda</LabelInput>
                        <Select isMulti={true} placeholder={null} options={OptVendaSituacao} onChange={(option) => setVendaSituacao(option)} value={VendaSituacao} />
                    </ColSm5>
                </Row>

                <Row>
                    <ColSm6>
                        <LabelInput>Ordenar por</LabelInput>
                        <Select placeholder={null} options={OptOrdenarPor} onChange={(options) => setOrdenarPor(options)} value={OrdenarPor} />
                    </ColSm6>

                    <ColSm6>
                        <LabelInput>Ordem</LabelInput>
                        <Select placeholder={null} options={OptOrdem} onChange={(opt) => setOrdem(opt)} value={Ordem} />
                    </ColSm6>
                </Row>
            </div>

            <Footer>
                <ButtonPrimary disabled={Loading} onClick={onGeneratePDF}>
                    {Loading ? <Spinner color="#fff" size={14} /> : "GERAR PDF"}
                </ButtonPrimary>
            </Footer>

            <DateCalendarModalV2 ref={RefModalData} />
        </div>
    );
}
