import "./configs/firebase";
// import './configs/websockets';

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./flatpickr.css";

/**
 * Contextos
 */
import WebRTCSocketProvider from "./contexts/webrtc-socket";
import SelfCheckoutsSocketProvider from "./contexts/selfcheckouts-socket";

/**
 * Rotas default
 */
import MainRoutes from "./routes";

/**
 * Create root
 */
const root = createRoot(document.getElementById("root"));

/**
 * Render default
 */
root.render(
    <SelfCheckoutsSocketProvider>
        <WebRTCSocketProvider>
            <React.StrictMode>
                <MainRoutes />
            </React.StrictMode>
        </WebRTCSocketProvider>
    </SelfCheckoutsSocketProvider>,
);
