import { Buffer } from 'buffer';

// API
import api from '~/services/api';

// CONSTs
import CONSTS_AS from '~/consts/local_storage';

// verifica se o usuário está logado
const getToken = () => {
    const token = window.localStorage.getItem(CONSTS_AS.AS_USER_TOKEN);

    if (token !== null) {
        return token;
    } else {
        // usuário não está logado
        return false;
    }
};

// atualiza o token
const setToken = (token) => {
    window.localStorage.setItem(CONSTS_AS.AS_USER_TOKEN, token);
};

// funcão de login
const login = async (email, senha) => {
    try {

        const baseURL = `?email=${email}&senha=${senha}`;

        const { data: response_data } = await api.get(`/public/monitoradores/login${baseURL}`, { responseType: 'json' });

        if (response_data.status !== 'error') {
            // gero o token de autenticação
            const token = Buffer.from(`${email}:${senha}`).toString('base64');

            // atualiza os dados do usuário
            setUserData(response_data);

            // atualiza o token
            setToken(token);

            return true;
        } else {
            throw new Error(response_data.message);
        }

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }
};

const logout = async () => {
    window.localStorage.removeItem(CONSTS_AS.AS_USER_TOKEN);
}

const setLastUserLogged = async (email = '') => {
    window.localStorage.setItem(CONSTS_AS.AS_LAST_USER_LOGGED, email);
}

const getLastUserLogged = () => {
    const last_user_logged = window.localStorage.getItem(CONSTS_AS.AS_LAST_USER_LOGGED);

    if (last_user_logged !== null) {
        return last_user_logged;
    } else {
        // usuário não está logado
        return '';
    }
}

const setUserData = async (data = {}) => {
    window.localStorage.setItem(CONSTS_AS.AS_USER_DATA, JSON.stringify(data));
}

const getUserData = () => {
    const last_user_logged = window.localStorage.getItem(CONSTS_AS.AS_USER_DATA);

    if (last_user_logged !== null) {
        return JSON.parse(last_user_logged);
    } else {
        // usuário não está logado
        return '';
    }
}

const checkModule = (module = false) => {
    const user = getUserData();

    if (user?.master) {
        return true;
    }

    if (user?.permissoes_organizadas) {

        if (user.permissoes_organizadas[module]) {
            return true;
        } else {
            return false;
        }

    } else {
        return false;
    }
}

const checkPermission = (module = false, access = false) => {
    const user = getUserData();

    if (user?.master) {
        return true;
    }

    if (user?.permissoes_organizadas) {

        if (user.permissoes_organizadas[module]?.[access]) {
            return true;
        } else {
            return false;
        }

    } else {
        return false;
    }
}

const AuthService = {
    login,
    logout,
    getToken,
    setToken,
    setLastUserLogged,
    getLastUserLogged,
    setUserData,
    getUserData,

    // permissões
    checkModule,
    checkPermission,
}

export default AuthService;