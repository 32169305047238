import styled from 'styled-components';

export const Main = styled.div`
    font-family: 'Inter';
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Content = styled.div`
    width: calc(100% - (20px * 2));
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 22px;
`;